



















































































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import Task from '@/tasks/models/Task';
import {TaskType} from '@/tasks/types/TaskType';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import STextarea from '@/common/components/STextarea.vue';

export default defineComponent({
  name: 'ubcTitrationReportSheet2',
  components: {
    STextarea,
    CalculationInput,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  props: {
    task: {
      type: Object as PropType<Task<TaskType.DynamicQuestion>>,
      required: true,
    },
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    return {
      inputs: {
        initialPH: null,
        pHEqPt: null,
        Veq: null,
        pHHalfEqPt: null,
        pKa: null,
        Ka: null,
        acidIdentity: null,
        studentAnswer: null,
      },
      options: [
        {text: '$\\text{Acetic acid, pK}_\\text{a}=\\text{4.76}$', value: 'acetic'},
        {text: '$\\text{KHP, pK}_\\text{a}=\\text{5.41}$', value: 'khp'},
        {text: '$\\text{Lactic aid, pK}_\\text{a}=\\text{3.86}$', value: 'lactic'},
      ],

      attachments: [] as File[],
    };
  },
});
